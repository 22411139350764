<template>
  <div class="app-home">
    <div class="app-home-head">
      TECH DOC. EDITOR Import
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm">退出</el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div class="app-home-table">
      <div class="table-name">
        <span>{{dataType}}中文列表</span>
        <el-button type="danger" @click="specTestClick" round>
          <template>
            <i class="el-icon-guide"></i>
            <span>中文 Spec 测试</span>
          </template>
        </el-button>
        <el-button type="primary" @click="handleCoaClick">
          <template>
            <i class="el-icon-plus"></i>
            <span>COA</span>
          </template>
        </el-button>
        <el-button type="warning" @click="handleVerifyListClick">
          <template>
            <span>Verify List</span>
          </template>
        </el-button>
        <el-button v-if="userInfo.operateStatus" type="primary" @click="handleClick">
          <template>
            <i class="el-icon-plus"></i>
            <span>新增</span>
          </template>
        </el-button>
      </div>

      <el-row style="padding: 10px">
        <el-col :span="5">
          <span>产品名称：</span>
          <!-- <el-input v-model="searchData.name" style="width:350px;"></el-input> -->
          <el-autocomplete
            clearable
            v-model="searchData.name"
            :fetch-suggestions="nameSearch"
            placeholder="请输入产品名称"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectName"
          ></el-autocomplete>
        </el-col>
        <el-col :span="5">
          <div>
            <span>产品编号：</span>
            <!-- <el-input v-model="searchData.code" style="width:350px;"></el-input> -->
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="searchData.code"
              :fetch-suggestions="codeSearch"
              placeholder="请输入产品编号"
              @input="handleSelectCode"
            ></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="9">
          <div style="margin-right: 20px">
            <el-radio-group
              v-model="dataType"
              size="medium"
              @change="dataTypeChange"
            >
              <el-radio-button label="Spec"></el-radio-button>
              <el-radio-button label="SDS"></el-radio-button>
              <el-radio-button label="原料组成"></el-radio-button>
              <el-radio-button label="Conversion"></el-radio-button>
              <el-radio-button label="TDS"></el-radio-button>
              <el-radio-button label="Test Method"></el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="3">
          <div>
            <el-button @click="handleSearch('查询')" type="primary"
              >查询</el-button
            >
            <el-button @click="handleSearch('重置')">重置</el-button>
          </div>
        </el-col>
      </el-row>

      <div
        :class="[{ 'table-list': true }, screen_ratio]"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="Desperately loading..."
        style="height: 77vh"
      >
        <el-table v-if="dataType != 'Conversion' && dataType != 'TDS' && dataType != 'Test Method'"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="productName" label="产品名称"></el-table-column>
          <el-table-column prop="itemCode" label="产品编号">
            <template slot="header">
              <span>产品编号</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': itemCodeSort,
                }"
                @click="handleSort('itemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <span :style="{ color: isRepeat(scope.row.itemCode) }">{{
                scope.row.itemCode
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="confirmed" label="审核">
            <template slot="header">
              <span>审核</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': verifiedSort,
                }"
                @click="handleSort('confirmed')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <!-- <i
                :class="verify_show(scope.row).icon"
                :style="{ color: verify_show(scope.row).color }"
              ></i> -->
              <i v-if="scope.row.confirmed==1" class="el-icon-circle-check" style="color:#67c23a;"></i>
            </template>
          </el-table-column>
          <el-table-column prop="verifiedListUser" label="修改者"></el-table-column>
          <el-table-column prop="verifiedBy" label="审核者" v-if="dataType == 'Spec' || dataType == 'SDS'"></el-table-column>
          <el-table-column prop="createName" label="创建者" v-else></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="140">
            <template slot="header">
              <span>创建时间</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': createTimeSort,
                }"
                @click="handleSort('createTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="修改时间" width="140">
            <template slot="header">
              <span>修改时间</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': updateTimeSort,
                }"
                @click="handleSort((dataType == 'Spec' || dataType == 'SDS')?'verifiedTime':'editTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime((dataType == 'Spec' || dataType == 'SDS')?scope.row.verifiedTime:scope.row.updateTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">修改</span>
              <span
                v-if="(dataType === 'Spec' || dataType === 'SDS') && userInfo.operateStatus"
                class="table-edit"
                @click="handleClick(scope.row, '复制')"
                >复制</span>
              <span
                v-if="
                  username === 'JamesChen' ||
                  username === 'admin' ||
                  username === 'JiaZhang'
                "
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="handleClick(scope.row, '删除')"
                >删除</span>
            </template>
          </el-table-column>
        </el-table>

        <el-table v-if="dataType == 'Conversion' || dataType == 'TDS'"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column v-if="dataType == 'Conversion'">
            <template slot="header">
              <span>产品编码</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': newItemCodeSort,
                }"
                @click="handleSort('newItemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.newItemCode }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="dataType == 'Conversion'">
            <template slot="header">
              <span>转换前产品编码</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': oldItemCodeSort,
                }"
                @click="handleSort('oldItemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.oldItemCode }}</span>
            </template>
          </el-table-column> 
          <el-table-column v-if="dataType == 'TDS'">
            <template slot="header">
              <span>产品编码</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': itemCodeSort,
                }"
                @click="handleSort('itemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.itemCode }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="产品名称"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="140">
            <template slot="header">
              <span>创建时间</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': createTimeSort,
                }"
                @click="handleSort('createTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="confirmed" label="审核">
            <template slot-scope="scope">
              <i v-if="scope.row.confirmed==1" class="el-icon-circle-check" style="color:#67c23a;"></i>
            </template>
          </el-table-column>
          <el-table-column prop="note" label="备注"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">              
              <i v-if="scope.row.confirmed==2" class="el-icon-s-flag" style="color:red;font-size: 20px;"></i>
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">修改</span>
              <span
                v-if="username === 'JamesChen'"
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="handleClick(scope.row, '删除')"
                >删除</span>
              <el-button v-if="dataType == 'Conversion' && scope.row.confirmed==1" type="info" @click="handleTdsClick(scope.row)">TDS</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-table v-if="dataType == 'Test Method'"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="methodCode" label="方法编号"></el-table-column>
          <el-table-column prop="methodName" label="方法名称"></el-table-column>
          <el-table-column prop="usage" label="用途"></el-table-column>
          <el-table-column prop="reference" label="参考法典"></el-table-column>
          <el-table-column prop="confirmed" label="操作程序">
            <template slot-scope="scope">
              <i v-if="scope.row.confirmed==1" class="el-icon-circle-check" style="color:#67c23a;"></i>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <i class="el-icon-s-flag" style="color:red;font-size: 20px;" v-if="scope.row.confirmed==2"></i>
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">修改</span>
              <span
                v-if="username === 'JamesChen'"
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="handleClick(scope.row, '删除')"
                >删除</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="table-pagition">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageIndex"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";

export default {
  name: "home",
  mixins: [base],
  data() {
    return {
      loading: false,
      dataType: "Spec",
      tableData: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        lot: "",
        itemCode: "",
        productName: "",
        verifiedList:0,
      },
      searchData: {
        name: "",
        code: "",
      },
      preview: {},
      createTimeSort: true,
      updateTimeSort: true,
      verifiedTimeSort: true,
      itemCodeSort: true,
      newItemCodeSort: true,
      oldItemCodeSort: true,
      verifiedSort: true,
      screen_ratio: "",
      nameList: [],
      codeList: [],
      getSearchList: true,
      basInfo:{},
      multipleSelection: [],
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      localStorage.setItem("loginUserNameKey", userInfo.username);
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    specTestClick(){
      // this.$router.push({ path: "cn-home2"});
      localStorage.setItem("dataType", 'Spec');
      this.$router.push({ path: "home2",query:{language:"CN" } });
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },
    handleSelectName() {
      // this.handleSearch("查询");
    },
    handleSelectCode() {
      // this.handleSearch("查询");
    },
    handleTdsClick(val){
      this.$router.push({ path: "cn-item-tds", query: { convId: val.id }});
    },
    handleVerifyListClick(){
      this.$router.push({ path: "cn-verifylist"});
    },
    nameSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCnListKey"));
      if (this.dataType == "SDS") {
        codeList = JSON.parse(localStorage.getItem("itemCodeCnSdsListKey"));
      }
      else if (this.dataType == "原料组成") {
        codeList = JSON.parse(localStorage.getItem("itemCodeCnRawmaterialListKey"));
      }
      var list = [];
      codeList.forEach((element) => {
        if (this.dataType == "SDS") {
          list.push({ value: element.productType });
        }
        else if (this.dataType == "原料组成") {
          list.push({ value: element.productName });
        }
        else if (this.dataType == "Spec") {
          list.push({ value: element.productType });
        }
      });
      // var restaurants = this.nameList;
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    codeSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCnListKey"));
      if (this.dataType == "SDS") {
        codeList = JSON.parse(localStorage.getItem("itemCodeCnSdsListKey"));
      }
      else if (this.dataType == "原料组成") {
        codeList = JSON.parse(localStorage.getItem("itemCodeCnRawmaterialListKey"));
      }
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    isRepeat(val) {
      let isRepeat = 0;
      this.tableData.forEach((v) => {
        if (val == v.itemCode) {
          isRepeat++;
        }
      });
      if (isRepeat > 1) {
        if (this.dataType === "Spec" || this.dataType === "SDS") {
          return "red";
        } else {
          return "black";
        }
      } else {
        return "black";
      }
    },
    getTime(time) {
      if (time) {
        return this.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    logout() {
      let token = localStorage.getItem("specToken");
      let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
      this.$api
        .logout({ id, token })
        .then(() => {
          this.$router.replace({ path: "/cn-login" });
          this.$message({ type: "success", message: "退出成功" });
          localStorage.clear("specToken");
          localStorage.clear("specUserInfo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm() {
      this.$confirm("确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getData(this.params);
    },
    handleClick(val, type) {
      switch (type) {
        case "删除":
          {
            this.$confirm("这将永久删除数据。继续?", "提示", {
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
              distinguishCancelAndClose: true,
              type: "warning",
            }).then(() => {
              this.deleteItem(val);
            });
          }
          break;
        case undefined:
          {
            localStorage.setItem("searchDataCnParamsKey", JSON.stringify(this.params));
            if (this.dataType === "Spec") {
              this.$router.push({
                path: "cn-detail",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "SDS") {
              this.$router.push({
                path: "cn-detail-sds",
                query: { id: val.id },
              });
            }
            else if (this.dataType === "原料组成") {
              this.$router.push({
                path: "cn-safety",
                query: { id: val.id },
              });
            }  
            else if (this.dataType === "TDS") {
              this.$router.push({
                path: "cn-item-tds",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "Conversion") {
              this.$router.push({
                path: "cn-item-conversion",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "Test Method") {
              this.$router.push({
                path: "cn-item-method",
                query: { id: val.id },
              });
            } 
          }
          break;
        case "编辑":
          {
            localStorage.setItem("searchDataCnParamsKey", JSON.stringify(this.params));
            if (this.dataType === "Spec") {
              this.basInfo=JSON.parse(val.jsonValue)
              this.$router.push({
                path: "cn-detail",
                query: { id: val.id,productType:this.basInfo.productType },
              });
            } 
            else if (this.dataType === "SDS") {
              this.basInfo=JSON.parse(val.jsonValue)
              this.$router.push({
                path: "cn-detail-sds",
                query: { id: val.id,productType:this.basInfo.productType },
              });
            }
            else if (this.dataType === "原料组成") {
              this.$router.push({
                path: "cn-safety",
                query: { id: val.id },
              });
            }
            else if (this.dataType === "TDS") {
              this.$router.push({
                path: "cn-item-tds",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "Conversion") {
              this.$router.push({
                path: "cn-item-conversion",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "Test Method") {
              this.$router.push({
                path: "cn-item-method",
                query: { id: val.id },
              });
            } 
          }
          break;
        case "复制":
          {
            if (this.dataType === "Spec") {
              this.$confirm("这将复制数据. 继续?", "提示", {
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
                distinguishCancelAndClose: true,
                type: "warning",
              }).then(() => {
                this.copyCnSpecItem(val);
              });
            } 
            else if (this.dataType === "SDS") {
              this.$confirm("这将复制数据. 继续?", "提示", {
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
                distinguishCancelAndClose: true,
                type: "warning",
              }).then(() => {
                this.copyCnSdsItem(val);
              });
            }
          }
          break;
        default:
          break;
      }
    },
    deleteItem(val) {
      if (this.dataType === "Spec") {
        this.$api.handleCnSpec
          .deleteItem(val.id)
          .then((data) => {
            console.log(data);
            this.getCnSpecData(this.params);
            this.$message({ type: "success", message: "删除成功" });
          })
          .catch((err) => {
            console.log(err);
          });
      } 
      else if (this.dataType === "SDS") {
        this.$api.handleCnSds
          .deleteSdsItem(val.id)
          .then((data) => {
            console.log(data);
            this.getCnSdsData(this.params);
            this.$message({ type: "success", message: "删除成功" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "原料组成") {
        this.$api.handleCnRawmaterial
          .deleteCnRawmaterial(val.id)
          .then((data) => {
            console.log(data);
            this.getCnRawmaterialList(this.params);
            this.$message({ type: "success", message: "删除成功" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      
      else if (this.dataType === "Conversion") {
        this.$api.handleConversion
          .conversionDelete(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "TDS") {
        this.$api.handleTds
          .tdsDelete(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "Test Method") {
        this.$api.handleTestMethod
          .testMethodDelete(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return;
    },
    copyCnSpecItem(val) {
      this.$api.handleCnSpec
        .copyCnSpecItem(val.id)
        .then((data) => {
          console.log(data);
          this.getData(this.params);
          this.$message({ type: "success", message: "复制成功" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copyCnSdsItem(val) {
      this.$api.handleCnSds
        .copyCnSdsItem(val.id)
        .then((data) => {
          console.log(data);
          this.getData(this.params);
          this.$message({ type: "success", message: "复制成功" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dataTypeChange() {
      localStorage.setItem("listDataTypeCnKey", this.dataType);
      this.getSearchList = true;
      this.getData(this.params);
    },
    getData(params) {
      this.loading = true;
      if (this.dataType === "Spec") {
        this.getItemCodeCnListData({});
        this.getCnSpecData(params);
        return;
      }
      else if (this.dataType == "SDS") {
        this.getItemCodeCnSdsListUrl({});
        this.getCnSdsData(params);
        return;
      }
      else if (this.dataType == "原料组成") {
        this.getItemCodeCnRawmaterialListUrl({});
        this.getCnRawmaterialList(params);
        return;
      }
      if (this.dataType == "Conversion") {
        this.getConversionData(params);
        return;
      }
      else if (this.dataType == "TDS") {
        this.getTdsData(params);
        return;
      }
      else if (this.dataType == "Test Method") {
        this.getTestMethodData(params);
        return;
      }
      return;
    },
    getCnSpecData(params) {
      this.$api.handleCnSpec
        .getCnSpecTableUrl(params)
        .then((data) => {
          console.log("specdataList");
          console.log(data);
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getCnSdsData(params) {
      this.$api.handleCnSds
        .getCnSdsTableUrl(params)
        .then((data) => {
          console.log("sdsdataList");
          console.log(data);
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getCnRawmaterialList(params) {
      this.$api.handleCnRawmaterial
        .getCnRawmaterialList(params)
        .then((data) => {
          console.log("CnRawmaterialList");
          console.log(data);
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            // this.codeList = data.records.map((v) => {
            //   return { value: v.itemCode };
            // });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getConversionData(params) {
      console.log("getConversionData");
      params.language=1
      this.$api.handleConversion
        .getConversionList(params)
        .then((data) => {
          console.log(data)
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getTdsData(params) {
      console.log("getTdsData");
      params.language=1
      this.$api.handleTds
        .getTdsList(params)
        .then((data) => {
          console.log("getTdsData");
          console.log(data)
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getTestMethodData(params) {
      console.log("getTestMethodData");
      params.language=1
      this.$api.handleTestMethod
        .getTestMethodList(params)
        .then((data) => {
           console.log(data)
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSearch(type) {
      switch (type) {
        case "重置":
          {
            this.searchData = { name: "", code: "" };
          }
          break;
        case "查询":
          {
            let { name, code } = this.searchData;
            this.params.itemCode = code;
            this.params.productName = name;
            this.params.pageIndex = 1;
            this.getData(this.params);
          }
          break;
        default:
          break;
      }
    },
    verify_show(val) {
      switch (val.confirmed) {
        case "true":
          return { icon: "el-icon-success", color: "#67c23a" };
        case "false":
          return { icon: "el-icon-error", color: "#f56c6c" };
        default:
          return { icon: "el-icon-question", color: "#a6a9ad" };
      }
    },
    handleSort(val) {
      switch (val) {
        case "createTime":
          {
            this.createTimeSort = !this.createTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.createTime).getTime();
              let bTime = new Date(b.createTime).getTime();
              return this.createTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "editTime":
          {
            this.updateTimeSort = !this.updateTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.updateTime).getTime();
              let bTime = new Date(b.updateTime).getTime();
              return this.updateTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "verifiedTime":
          {
            this.verifiedTimeSort = !this.verifiedTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.verifiedTime).getTime();
              let bTime = new Date(b.verifiedTime).getTime();
              return this.verifiedTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "confirmed":
          {
            this.verifiedSort = !this.verifiedSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.confirmed == undefined ? "" : a.confirmed+"";
              let bitemCode = b.confirmed == undefined ? "" : b.confirmed+"";
              return this.verifiedSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        case "itemCode":
          {
            this.itemCodeSort = !this.itemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.itemCode;
              let bitemCode = b.itemCode;
              return this.itemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        case "newItemCode":
          {
            this.newItemCodeSort = !this.newItemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.newItemCode;
              let bitemCode = b.newItemCode;
              return this.newItemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        case "oldItemCode":
          {
            this.oldItemCodeSort = !this.oldItemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.oldItemCode;
              let bitemCode = b.oldItemCode;
              return this.oldItemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        default:
          break;
      }
    },
    getItemCodeListData(params) {
      this.$api
        .getItemCodeList(params)
        .then((data) => {
          localStorage.setItem("itemCodeListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getItemCodeCnListData(params) {
      this.$api
        .getItemCodeCnListData(params)
        .then((data) => {
          localStorage.setItem("itemCodeCnListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getZhCnItemCodeList(params) {
      this.$api.handleCnSpec
        .getZhCnItemCodeList(params)
        .then((data) => {
          localStorage.setItem("itemCodeZhCnListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getItemCodeCnSdsListUrl(params) {
      this.$api.handleCnSds
        .getItemCodeCnSdsListUrl(params)
        .then((data) => {
          localStorage.setItem("itemCodeCnSdsListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getItemCodeCnRawmaterialListUrl(params) {
      this.$api.handleCnRawmaterial
        .getItemCodeCnRawmaterialList(params)
        .then((data) => {
          localStorage.setItem("itemCodeCnRawmaterialListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleCoaClick(){
      this.$router.push({ path: "cn-coa"});
    },
  },
  mounted() {
    this.getItemCodeCnListData({});
    this.getZhCnItemCodeList({});
  },
  created() {
    let type = localStorage.getItem("listDataTypeCnKey");
    if (type == null || typeof type == "undefined") {
      this.dataType = "Spec";
    } else {
      this.dataType = type;
    }
    console.info('-----this.getCnData(this.params);---')
    let searchDataCnParams = localStorage.getItem("searchDataCnParamsKey");
    if(searchDataCnParams){
      console.info(searchDataCnParams)
      this.params = JSON.parse(searchDataCnParams);
      this.searchData = { name: this.params.productName, code: this.params.itemCode };
    }
    this.getData(this.params);
    // this.getPreview();
    let sheight = window.screen.height;
    let swidth = window.screen.width;
    let ratio = swidth / sheight;
    switch (ratio) {
      case 16 / 9:
        this.screen_ratio = "ratio-16-9";
        break;
      case 16 / 10:
        this.screen_ratio = "ratio-16-10";
        break;
      case 3 / 2:
        this.screen_ratio = "ratio-3-2";
        break;
      case 4 / 3:
        this.screen_ratio = "ratio-4-3";
        break;
      default:
        this.screen_ratio = "ratio-16-10";
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>